import React from 'react'

import Navbar from './Navbar'
import Footer from './Footer'



export default function Layout({ children }: {
  children: React.ReactElement | React.ReactElement[]
}) {
  return (
    <main
      className={`flex bg-white min-h-screen flex-col text-black font-inter`}
    >
      <Navbar />
      <div className='min-h-screen'>
        {children}
      </div>
      <Footer />
    </main>
  )
}
