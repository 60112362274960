import React from 'react'
import { Helmet } from 'react-helmet';
import { Blog } from 'types';

export default function ReactHelmet({ data }: { data: Blog }) {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{data?.title}</title>
            <meta name="keywords" content={data.keywords.join(', ')} />
            <link rel="canonical" href={`https://numerogyan.in${data.path}`} />
        </Helmet>
    )
}
