import React, { useEffect } from 'react';
import { Layout } from 'components'; // Import your layout component
import { BlogLayout, BlogTitle, BlogSubtitle, BlogParagraph, BlogBulletPoints, BlogQuote, BlogImage } from '../components'; // Import your reusable blog components
import { Blog } from 'types';
import { Track } from '../../../firebase';
import ReactHelmet from 'components/Helmet';

const LifePathNumber6Blog = (data: Blog) => {
    useEffect(() => {
        window.scrollTo(0, 0)
        Track(data.path)
        // Add any necessary side effects here
    }, []);

    return (
        <Layout>
            <BlogLayout>
                <ReactHelmet data={data} />
                <BlogTitle>Love and Responsibility: Life Path Number 6</BlogTitle>
                <BlogImage src={data.imageURL} alt={data.keywords.join(', ')} tags={data.keywords} />
                {/* Introduction */}
                <BlogSubtitle>Finding Love and Responsibility with Life Path Number 6</BlogSubtitle>
                <BlogParagraph>
                    Life Path Number 6 is a path of love and responsibility. Those born under this number are natural caregivers, deeply connected to family and community. In this blog, we will explore the world of Life Path Number 6, uncovering the significance of love and the importance of responsibility it brings to life.
                </BlogParagraph>

                {/* The Traits of Life Path Number 6 */}
                <BlogSubtitle>The Traits of Life Path Number 6</BlogSubtitle>
                <BlogParagraph>
                    <BlogBulletPoints>
                        <li><strong>Love and Compassion:</strong> Life Path Number 6 individuals are characterized by their immense capacity for love and compassion. They genuinely care for others' well-being.</li>
                        <li><strong>Responsibility:</strong> Responsibility is their strong suit. They take their obligations seriously, whether in their family, community, or professional roles.</li>
                        <li><strong>Harmony:</strong> Creating harmony in their surroundings is important to them. They are often peacemakers who work to maintain balance in relationships and situations.</li>
                        <li><strong>Nurturers:</strong> They have a nurturing nature, often taking on caretaker roles for loved ones and providing support in times of need.</li>
                    </BlogBulletPoints>

                </BlogParagraph>

                {/* The Strengths of Life Path Number 6 */}
                <BlogSubtitle>The Strengths of Life Path Number 6</BlogSubtitle>
                <BlogParagraph>
                    <BlogBulletPoints>
                        <li><strong>Loving Relationships:</strong> They excel at building loving and harmonious relationships. Their compassionate nature fosters deep connections with others.</li>
                        <li><strong>Reliability:</strong> Life Path Number 6 individuals are reliable and dependable. They fulfill their responsibilities with unwavering commitment.</li>
                        <li><strong>Problem Solvers:</strong> They are skilled problem solvers, finding resolutions that benefit everyone involved and contribute to a peaceful environment.</li>
                        <li><strong>Community Builders:</strong> Their commitment to their communities and families makes them natural builders of strong, supportive networks.</li>
                    </BlogBulletPoints>

                </BlogParagraph>

                {/* Embracing Love and Responsibility */}
                <BlogSubtitle>Embracing Love and Responsibility with Life Path Number 6</BlogSubtitle>
                <BlogParagraph>
                    <BlogBulletPoints>
                        <li><strong>Express Your Love:</strong> Show your love and compassion openly to those around you. Your care can positively impact the lives of others.</li>
                        <li><strong>Fulfill Responsibilities:</strong> Continue to fulfill your responsibilities with dedication and reliability. Your contributions are highly valued.</li>
                        <li><strong>Create Harmony:</strong> Be a peacemaker in your relationships and communities. Strive for harmony and balance in all interactions.</li>
                        <li><strong>Nurture Your Loved Ones:</strong> Keep nurturing and supporting your loved ones. Your caring nature brings comfort and strength to those you care for.</li>
                    </BlogBulletPoints>

                </BlogParagraph>

                {/* In Conclusion */}
                <BlogSubtitle>In Conclusion</BlogSubtitle>
                <BlogParagraph>
                    Life Path Number 6 is a journey of love and responsibility. Embrace your gift of caring, nurturing, and supporting others, and continue to build harmonious relationships and communities. Your love and dedication are powerful forces for creating positive change and bringing people closer together.
                </BlogParagraph>
            </BlogLayout>
        </Layout>
    );
};

export default LifePathNumber6Blog;
