import React from 'react';

// Utility function to create a lazy-loaded route
export function createLazyRoute(path: string, component: () => React.ReactElement) {
    return {
        path,
        element: (
            <React.Suspense fallback={< div > Loading...</div>}>
                {component()}
            </React.Suspense>
        ),
    };
}

export function openTab(link: string) {
    const newTab = window.open(link, '_blank');
    newTab?.focus(); // Optional: Set focus to the new tab
}