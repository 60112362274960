import React from 'react';
import { benefitsList } from '../utils/locale'
import { LazyImage } from '.';

const BenefitsSection = () => {
    return (
        <section className="py-8 bg-gray-100">
            <div className="container mx-auto">
                <h3 className="text-center text-3xl font-extrabold text-gray-800 mb-4">Benefits of Numerology</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                    {benefitsList.map((benefit, index) => (
                        <div key={index} className="flex items-center bg-white rounded-md p-4 shadow">
                            <div className="mr-4 h-auto w-96">
                                <LazyImage src={benefit.icon} alt={benefit.title} className="w-full h-auto mx-auto" layout="responsive" width={1200} height={800} />
                            </div>
                            <div>
                                <h4 className="text-xl font-bold text-gray-800 mb-2">{benefit.title}</h4>
                                <p className="text-gray-600">{benefit.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default BenefitsSection;
