import React, { ReactNode } from 'react';

interface BlogLayoutProps {
    isMobile?: boolean;
    children: ReactNode
}

const BlogLayout: React.FC<BlogLayoutProps> = ({ isMobile = window.innerWidth < 768, children }) => {
    return (
        <div className="container mx-auto">
            {isMobile ? (
                // Mobile layout with ads between sections/paragraphs
                <div className="p-4">
                    {children}
                    {/* Place ads or other mobile-specific content here */}
                </div>
            ) : (
                // Desktop layout with three sections
                <div className="grid grid-cols-5 gap-6 ">
                    <div className="col-span-1">
                        {/* Side sections for ads or other desktop-specific content */}
                        {/* You can place ads here */}
                    </div>
                    <div className="col-span-3">
                        {/* Center section for the actual post content */}
                        {children}
                    </div>

                    <div className="col-span-1">
                        {/* You can place additional ads or content in this side section */}
                    </div>
                </div>
            )}
        </div>
    );
};

export default BlogLayout;
