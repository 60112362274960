import React from 'react';
import { splitTextIntoChunks } from 'utils/functions';

const ReadableContent = ({ header, content, subheader }: { header: string, content: string[] | string, subheader?: string }) => {
    let paragraph = typeof content === 'string' ? splitTextIntoChunks(content, 60) : content;
    return (
        <div className="my-8 p-4 bg-white shadow-md rounded-md">
            {header ? <h2 className="text-2xl md:text-4xl font-bold mb-4 text-blue-800">{header}</h2> : <></>}
            {subheader ? <h3 className="text-lg font-semibold mb-2 text-gold-500">{subheader}</h3> : <></>}
            <div className="prose prose-lg text-black">{paragraph.map((item, index) => <p key={index} className='mb-4'>{item}</p>)}</div>
        </div>
    );
};

export default ReadableContent;
