import React, { ReactElement } from 'react';

interface BlogBulletPointsProps {
    points?: string[];
    children?: ReactElement[]
}

const BlogBulletPoints: React.FC<BlogBulletPointsProps> = ({ points, children }) => {
    return (
        <ul className="list-disc list-inside">
            {points?.map((point, index) => (
                <li key={index} className="text-xl">
                    {point}
                </li>
            ))}
            {children?.map((point, index) => (point))}
        </ul>
    );
};

export default BlogBulletPoints;
