import React from 'react';

interface BlogTitleProps {
    title?: string;
    children?: string;
}

const BlogTitle: React.FC<BlogTitleProps> = ({ title, children }) => {
    return <h1 className="text-4xl font-bold my-8 text-center">{title || children}</h1>;
};

export default BlogTitle;
