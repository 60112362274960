// StripeContainer.js
import React, { useEffect, useState } from 'react';
import { loadStripe, StripeElementsOptions, Stripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import StripePaymentButton from './StripePaymentButton';
import { Loader } from 'components';

const BASE_URL = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:5001/numero-gyan/us-central1' : 'https://us-central1-numero-gyan.cloudfunctions.net'

const StripeContainer = () => {
    const [stripePromise, setStripePromise] = useState<PromiseLike<Stripe | null> | Stripe | null>(null);
    const [clientData, setClientData] = useState<StripeElementsOptions>({ clientSecret: undefined, mode: undefined })

    useEffect(() => {
        fetch(`${BASE_URL}/getPublishKey`).then(async (r) => {
            const { publishableKey } = await r.json();
            setStripePromise(loadStripe(publishableKey));
        }).catch((e) => console.log('stripePromise error', e))
    }, [])

    useEffect(() => {
        fetch(`${BASE_URL}/createPaymentIntent`, {
            method: 'POST',
            body: JSON.stringify({
                currency: "inr",
                amount: 49900,
            })
        }).then(async (res) => {
            if (res.ok) {
                const { clientSecret, amount, currency } = await (res.json());
                setClientData({ clientSecret, amount, currency });
            }
        }).catch((e) => console.log('clientSecret error', e))
    }, [])

    return (
        <>
            {!stripePromise && <Loader />}
            {
                stripePromise && clientData?.clientSecret && (
                    <Elements stripe={stripePromise} options={{ clientSecret: clientData.clientSecret }}>
                        <StripePaymentButton data={clientData} />
                    </Elements>)
            }
        </>
    );
};

export default StripeContainer;
