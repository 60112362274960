// src/slices/analysisSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';

interface AnalysisState {
    analysisData: any; // Define the structure of your analysis data
}

const initialState: AnalysisState = {
    analysisData: null,
};

const analysisSlice = createSlice({
    name: 'analysis',
    initialState,
    reducers: {
        updateAnalysisData: (state, action: PayloadAction<any>) => {
            state.analysisData = action.payload;
        },
    },
});

export const getanalysisData = (state: RootState) => state.analysis.analysisData

export const { updateAnalysisData } = analysisSlice.actions;

export default analysisSlice.reducer;
