export const CHALDEAN_NAME_NUMBER: Record<string, number> = {
    A: 1,
    B: 2,
    C: 3,
    D: 4,
    E: 5,
    F: 8,
    G: 3,
    H: 5,
    I: 1,
    J: 1,
    K: 2,
    L: 3,
    M: 4,
    N: 5,
    O: 7,
    P: 8,
    Q: 1,
    R: 2,
    S: 3,
    T: 4,
    U: 6,
    V: 6,
    W: 6,
    X: 5,
    Y: 1,
    Z: 7,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9
};

export const AppName = `Numero Gyan`

export const WelcomeNoteLocales = {
    header: 'Welcome to Numero Gyan, where the ancient wisdom of number meets modern insight.',
    d1: `At Numero Gyan, unlock your true potential with our transformative numerology services. Gain deep self-understanding, uncover hidden talents, and harness cosmic energies to shape your life's path. Our numerology tool offers personalized insights and practical tools for clarity in relationships, career choices, and personal growth. Embrace informed decisions and embrace your true potential with our profound numerology readings and reports.`,
    benefits: 'Experience the Benefits of Numerology:',
    list: [
        "Discover your hidden strengths and increase self-awareness.",
        "Align with your life purpose and make authentic choices.",
        "Navigate challenges with clarity and resilience by uncovering life patterns.",
        "Enhance relationships through understanding compatibility.",
        "Optimize success and personal growth during favorable periods.",
        "Unveil the power and significance within your birthdate and name."
    ],
    dontLet: `Don't let the mysteries of life pass by you!`,
    journey: `Start your journey with us today and experience the profound impact it can have on your life.`
}

export const benefitsList = [
    {
        title: 'Enhanced Decision Making',
        description: 'Numerology reveals your traits and helps you make the correct decisions about relationships, health, education, marriage, love, finance, and more.',
        icon: require('assets/benefits/decision-making.jpg')
    },
    {
        title: 'Improving Relationships',
        description: 'Numerology assists in better understanding individuals, which strengthens relationships and fosters better communication and compatibility.',
        icon: require('assets/benefits/relationships.jpg')
    },
    {
        title: 'Self-Discovery',
        description: 'Numerology provides insights into your strengths, weaknesses, potential, and talents, enabling better self-understanding and personal growth.',
        icon: require('assets/benefits/self-discovery.jpg')
    },
    {
        title: 'Luck and Success',
        description: 'Numerology can help you attract luck and success by aligning your name and date of birth in a harmonious way.',
        icon: require('assets/benefits/luck-success.jpg')
    },
    {
        title: "Preparation for Life's Events",
        description: 'Numerology assists in anticipating and preparing for unexpected events by identifying auspicious and inauspicious dates, lucky days, months, and years.',
        icon: require('assets/benefits/preparation.jpg')
    },
    {
        title: 'Make Life-Rewarding Choices',
        description: 'Numerology helps you make rewarding choices in various aspects of life, such as career decisions and investment opportunities.',
        icon: require('assets/benefits/career-choices.jpg')
    }
];

export const ToolHighlights = [
    {
        title: "Name Number Analysis",
        description: "Uncover the hidden meanings and influences behind your name through numerological analysis.",
    },
    {
        title: "Loshu Grid Analysis for Personality",
        description: "Explore how the Loshu Grid reveals insights into your personality traits and characteristics.",
    },
    {
        title: "Destiny Number",
        description: "Gain a deeper understanding of your destiny number and its significance in your life's journey.",
    },
    {
        title: "Grid Rows and Diagonals",
        description: "Discover the symbolism and interpretations associated with the rows and diagonals of the grid.",
    },
    {
        title: "Missing Number in the Grid",
        description: "Learn about the impact of missing numbers in the grid and explore remedies to harmonize their effects.",
    },
    {
        title: "Personal Year Analysis",
        description: "Get insights into your personal year for the current year and the next two years, and understand how it influences various aspects of your life.",
    },
];


export const AnalysisModules = {
    PsychicNumber: 'Psychic Number',
    LifePath: 'Life Path Number',
    NameNumber: 'Name Number',
    PersonalYear: 'Personal Year',
    LoshuGrid: 'Loshu Grid',
    Remedies: 'Remedies'
}
