type Impact = Record<string, string[]>

const NumberImpact: Impact = {
  1: [
    'People with just one 1 in their chart find it hard to express their innermost feelings, they may be good communicators in other ways but find it hard to explain their inner most voice. They often find it hard to see the other person point of view.',
    'You are good in communication. You also have the power to be a good assassin of a situation impartially.',
    'Generally talks too much, chatter box, happy & outgoing, good entertainer.',
    'If there are four 1111 in your chart you find it extremely easy to express yourself. You are a caring person but people frequently misunderstand you. You also find it very hard to relax and unwind',
    'They find enormous difficulty in expressing themselves verbally. They are frequently misunderstood and often direct they expressive skills into an area they find less painful i.e. writing, painting, dancing. Some people with this combination overindulge in food, drugs and alcohol.',
  ],
  2: [
    'If there is one 2 in your chart then you are sensitive and intuitive. Unfortunately you are easily hurt. They are able to sum up other people at a glance and have an un canny ability at detecting insincerity.',
    'These people are highly intelligent, sensitive & intuitive. Unlike people with single 2 you are able to make good use of your intuition. You can easily detect the motives of others and be able to access other at a glance.',
    'This chart says you have an over abundance of sensitive and intuitive energy and can easily be hurt. You may give impression of being aloof because you live in a world of your own feelings. You often prefer being on your own instead of spending time with others who might hurt their feelings.',
    'These people are very impatient and inclined to overreact to small problems. You are extremely sensitive and frequently prefer time by yourself rather than being hurt by others.',
    'There combination is very rare and very unfortunate too, as you find life extremely difficult you are overly sensitive, constantly suffer from self doubt and lack of confidence and trust in others.',
    'There combination is very rare and very unfortunate too, as you find life extremely difficult you are overly sensitive, constantly suffer from self doubt and lack of confidence and trust in others.',
  ],
  3: [
    'These people have a very good/creative brain and an excellent memory. Always have a positive approach to reach their goals. You are able to inspire others with your optimism and honesty.',
    'You possess very good imaginative power, mentally alert and usually creative. Many people with this combo become writer as you have ability to channel your creative imagination and express your ideas in words.',
    'People having 333 in their chart means they live inside their rich imaginations. You often find it hard to relate well with others and can appear self absorbed and remote. You possess excellent mental ability, but frequently spend your lives in a world of dreams. You find it hard to agree to others and can be argumentative and petty at times.',
    'Such people are very impractical and overly imaginative and fearful. All of these things made it hard for them to function well in everyday life.',
  ],
  4: [
    'People with one 4 are very Practical in life and usually good with hands. You enjoy "hands on" type occupations and get impatient with imaginative ideas & theories. You have the ability to organise others and carry out plans to perfection. You also relate to music and handicrafts in some cases.',
    'You are overly involved in physical and materialistic activities at the expense of other activities. You have excellent organisational skills and live starting a task at the beginning and carrying it through to the end. You have considerable creative ability in their hands and enjoy making beautiful objects. Such people do not like bending their hends for someone.',
    'They are involved almost exclusively with physical activities and find it hard to pay attention to other areas of their lives. Very well organised, self discipline and hard working people. You are obvious to others, but people with 3 fours frequently find it difficult to accept their natural talents and waste years working in the wrong fields.',
    'Totally immersed in physical activities and find it difficult to understand other people who are interested in intellectual or spiritual matters. They posses enormous ability at anything involving their hands. People with four 4 are susceptible to damage to their lower limbs',
  ],
  5: [
    'Five is placed in the center position of the grid and denotes balance and emotional stability. However it also relates to freedom, so people with a 5 in their chart need a certain space around them and are likely to run if they feel overly restricted. These people are emotionally well balanced, compassionate, understanding and caring. They are able to motivate and inspire others to achieve much more than otherwise would have been possible.',
    'These people are intense and determined. They have great deal of drive and enthusiasm. They have problems harnessing their emotion, which lead to outbursts that are latter regretted. This often created difficulties in home and family life.',
    'These people are prone to speaking without thinking and can hurt others unwittingly. They enjoy change adventure, excitement and often take unnecessary risks. they have great deal of drive and energy but these qualities need to be carefully channeled.',
    'This is unfortunate as you need to take remedies as such people can have unnatural death.',
  ],
  6: [
    'True lover of home and loved ones. You enjoy domestic responsibilities ans posses considerable creative potential. They make good parents and ultimately become the person everyone comes to in the family when things are not going well. They are often insecure and worry about being widowed and left on their own.',
    'You are inclined to worries unnecessarily about home and family. They are nervous stain which means they need more rest than most people. they enjoy creative activities and are happiest surrounded by beautiful things. They are usually over protective and find it hard to let their children stand on their own two feet.',
    'These type of people are inclined to look more on the negative side of life than the positive and always need constant encouragement. People with 666 in their chars are inclined to be overly protective and possessive of their loved ones. you have considerable creative potential, which provides a useful release for their emotional tension.',
    'Highly creative but find it hard to channel this energy in the early part of their life. Everything affects them emotionally, which makes it hard for them to fit into everyday life. Rare to see combination in coming times.',
  ],
  7: [
    'This type of people are likely to learn through losses of love, possession or health. As they learn from these experiences they usually become more and more interested in metaphysical or spiritual pursuits.',
    'People with two 7 in their chart grow in knowledge and wisdom bu losing either love, health or money. Ultimately this likely lead to interest in the physic or occult worlds. They have analytical brain which makes them good at solving intricate technical problems.',
    'People with three 7 in their chart often lead sad lives caused by major disappointments and setbacks in the area of love, health and money. Frequently these people develop tremendous reserves of inner strength as a result of these difficulties.',
    'People with four 7 in their chart have through major lessons in life involving loss of love, health and finance.',
  ],
  8: [
    "People with one 8 in their chart are methodical, conscientious and good with details. However surprisingly, they usually find it hard to finish the task they begin. They have restless, active minds and need constant mental challenges. They stick to their mindsets and it's hard to change their perception on something if they have made up their mind.",
    'People having 88 in their grid are extremely preceptive and conscientious. They Prefer to experience things for themselves rather than too much on trust. They have fixed view and opinions and find it hard to change their minds after decisions have been made. They are usually rigid and stubborn.',
    'People who are having 888 in their chart are more conscientious rigid frequently. They develop more purpose in life about the time they reach forty and then make rapid progress. They can be overly materialistic and need to learn possessions cannot bring lasting happiness.',
    'They are restless people and have a strong need for change and variety in their lives. Once they find something they really want to pursue their progress can be a joy to behold.. Until then though they are likely to lead a rather aimless pointless live.',
  ],
  9: [
    'People with one 9 in their chart are ambitious and have a strong desire to improve themselves. The number 9 is on both the mental and will plane. This is one reason humankind have achieved such a great deal in the 20 century.',
    'People with 99 in their chart are idealistic, intelligent and inclined to be critical of others. As they are highly intelligent they sometimes tend to look down on others who are not similarly blessed. They need to learn to mix with people from all levels of society.',
    'People with 999 in their chart are idealistic caring and intelligent. They are inclined to exaggerate and make mountains out of the hole. They learn to handle this better as they mature. When they are making good use of their mental capabilities, they are happy and positive. However they quickly become frustrated and despondent when they feel they are caught in a rut.',
    'People having 999 are highly intelligent but they find hard to live in everyday world. They retreat into a world of their own imagination. If they can learn to harness the enormous amount of power  and energy at their disposal they can become a real force for good in the world.',
  ],
};

export default NumberImpact;