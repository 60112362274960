// import { Events } from '../analyticsConstants';
import { Events } from '../analyticsConstants';
import { Track } from '../firebase'
import React from 'react';

const list = [
    '3 Years Prediction',
    'Missing Numbers Impact',
    'Remedies for Missing Numbers',
    'Suitable Occupations',
    'And More!'
]

const PDFBanner = () => {
    return (
        <div className="bg-gradient-to-r from-purple-500 to-blue-500 py-8">
            <div className="container mx-auto px-4">
                <div className="flex flex-col items-center justify-center text-white">
                    <h2 className="text-3xl md:text-4xl font-bold mb-4">Unlock Your Destiny!</h2>
                    <p className="text-lg md:text-xl mb-6 text-center">
                        Get personalized:
                    </p>
                    <ul className="text-lg md:text-xl mb-6 text-center">
                        {list.map(item => <li className='font-sans text-rose-300 font-semibold' key={item}>{item}</li>)}

                    </ul>
                    <a
                        href="/pdf-download-page"
                        className="bg-white text-blue-500 px-6 py-3 rounded-md shadow-md hover:bg-blue-500 hover:text-white transition-all duration-300 font-semibold"
                        onClick={() => Track(Events.goToDownloadReport)}
                    >
                        Download Your Personalized Report
                    </a>
                </div>
            </div>
        </div>
    );
};

export default PDFBanner;
