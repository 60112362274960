import { signInWithGoogle, signOutGoogle } from '../firebase';
import { LazyImage } from '.';
import React, { useState } from 'react';
import { useAuth } from 'provider/AuthContext';

const options = [
    {
        href: '/',
        title: 'Home'
    },
    {
        href: '/numerology-calculator',
        title: 'Numerology Calculator'
    },
    {
        href: '/blog',
        title: 'Blog'
    },
    {
        href: '/about',
        title: 'About Us'
    },
    {
        href: '/contact',
        title: 'Contact Us'
    },


]

const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const { user } = useAuth() || { user: null, signInUser: Promise<{}> };

    const handleGoogleSignin = async () => await signInWithGoogle();
    const handleGoogleSignOut = async () => await signOutGoogle();

    return (
        <nav className="bg-white p-4 px-8 flex justify-between border-b-2">
            <a href="/" className='h-16 w-16'>
                <LazyImage src={require("assets/images/logo.webp")} alt="Logo" className="h-16 w-16" width={42} height={42} />
            </a>

            <div className="hidden sm:flex">
                <ul className="text-black flex align-bottom justify-end">
                    {options.map(item => (
                        <a href={item.href} className="mx-2 px-2 py-2 hover:bg-slate-300 hover rounded-md m-auto" key={item.title}>{item.title}</a>
                    ))}
                </ul>
                {
                    user?.photoURL && user?.name ? (<img onClick={() => setMenuOpen(true)} className="h-8 w-8 rounded-full my-auto" src={user?.photoURL as string} alt="" />) : (
                        <button onClick={() => handleGoogleSignin()} className="bg-blue-500 mx-2 px-2 py-2 hover:bg-slate-300 hover rounded-md m-auto"><p className='text-yellow-50'>Login</p></button>
                    )
                }
            </div>
            <div className="sm:hidden flex">
                {
                    user?.photoURL || user?.name ? (<img onClick={() => setMenuOpen(true)} className="h-8 w-8 rounded-full my-auto" src={user?.photoURL as string} alt="" />) : (
                        <button className="text-white bg-blue-500 p-2 mr-3 rounded-md" onClick={() => handleGoogleSignin()}>
                            Login
                        </button>
                    )
                }
                {menuOpen && (
                    <ul className="absolute right-12 z-10 p-2 border-2 rounded-md border-gray-400 text-black flex flex-col mt-12 origin-top-right bg-blue-100" aria-orientation="vertical" aria-labelledby="user-menu-button">
                        {options.map(item => (
                            <a href={item.href} className="block px-4 py-2 text-sm text-gray-700" key={item.title}>{item.title}</a>
                        ))}
                    </ul>
                )}
            </div>
        </nav>

    );
};

export default Navbar;
