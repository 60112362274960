import React from 'react'
import { LazyImage } from '.';

const testimonialsImages = [
    require('assets/testimonials/1.png'),
    require('assets/testimonials/2.png'),
    require('assets/testimonials/3.png'),
    require('assets/testimonials/4.png'),
    require('assets/testimonials/5.png'),
    require('assets/testimonials/6.png'),
    require('assets/testimonials/7.png'),
    require('assets/testimonials/8.png'),
    require('assets/testimonials/9.png'),
    require('assets/testimonials/10.png'),
    require('assets/testimonials/11.png'),
    require('assets/testimonials/12.png')
]

export default function Testimonials() {
    return (
        <div className='p-4 bg-gray-800'>
            <h3 className='text-center text-3xl my-4 font-extrabold text-white'>Testimonials</h3>
            <div className='flex flex-row flex-wrap'>

                {
                    testimonialsImages.map((item, index) => (
                        <div key={index} className='flex md:flex-1 flex-col justify-start items-center m-2 md:min-w-[45%] bg-gray-200 rounded-md'>
                            <LazyImage src={item} alt="testimonial" className="object-fill" layout="responsive" width={1200} height={800} />
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
