import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';

interface PaymentState {
    payments: {
        [key: string]: string; // Define an index signature for string keys
    };
}

const initialState: PaymentState = {
    payments: {},
};

const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        recordPayment: (state, action: PayloadAction<any>) => {
            let payment = {
                [`${action.payload.pdfID}`]: action.payload.razorpay_payment_id
            }
            console.log('### state.payments', state.payments)
            state.payments = { ...state.payments, ...payment };
        },
    },
});

export const { recordPayment } = paymentSlice.actions;

export default paymentSlice.reducer;
