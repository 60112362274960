import React, { ReactNode } from 'react';

interface BlogParagraphProps {
    text?: string;
    children?: string | ReactNode
}

const BlogParagraph: React.FC<BlogParagraphProps> = ({ text, children }) => {
    return <p className="text-lg my-4 font-normal">{text || children}</p>;
};

export default BlogParagraph;
