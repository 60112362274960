import React from 'react';
import { LoshuGrid } from '../types';

const Grid = ({ data }: { data: LoshuGrid }) => {
    const gridValues = [4, 9, 2, 3, 5, 7, 8, 1, 6];

    return (
        <div className="flex justify-center items-center py-4">
            <div className="grid grid-cols-3 grid-rows-3 gap-0">
                {gridValues.map((value, index) => {
                    const digits = data[value] ? String(value).repeat(data[value]) : '';
                    const hasDigits = digits !== '';
                    const isMissing = !data[value] || data[value] === 0;

                    return (
                        <div
                            key={index}
                            className={`border border-teal-700 flex items-center justify-center h-24 w-24 ${hasDigits ? '' : 'bg-gray-400'
                                } text-white text-lg font-bold`}
                        >
                            <div className={`h-16 w-16 rounded-full flex items-center justify-center ${isMissing ? 'bg-gray-500' : 'bg-teal-700'}`}>
                                <span className={`${hasDigits ? 'text-white' : 'text-gray-600'} ${isMissing ? 'font-bold' : ''}`}>
                                    {isMissing ? value : digits}
                                </span>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default Grid;
