// AuthContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import { auth, signInWithGoogle, signOutGoogle } from "../firebase"; // Import your authentication functions
import { USER } from "types";


// Create an authentication context
const AuthContext = createContext<{ user: USER | null } | null>(null);

// Create an authentication provider component
export function AuthContextProvider({ children }: { children: React.ReactNode }) {
    const [user, setUser] = useState<USER | null>(null);

    useEffect(() => {
        // Listen for changes in authentication state
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                // Set your user data here based on authUser
                const userData: USER = {
                    uid: authUser?.uid || '',
                    name: authUser?.displayName,
                    authProvider: "google",
                    email: authUser?.email || '',
                    phoneNumber: authUser?.phoneNumber,
                    photoURL: authUser?.photoURL,
                    remainingPDFQuota: 0,
                    lastLogin: authUser?.metadata?.lastSignInTime || ''
                };
                setUser(userData);
            } else {
                setUser(null);
            }
        });

        // Clean up the listener when the component unmounts
        return () => unsubscribe();
    }, []);
    // Create a context value object with user and custom authentication functions
    const contextValue = {
        user
    };

    return (
        <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
    );
}

// Create a custom hook to access the authentication context
export function useAuth() {
    return useContext(AuthContext);
}
