// src/slices/userSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { USER } from 'types';

interface UserState {
    userData: USER; // Define the structure of your user data
}

const initialState: UserState = {
    userData: {
        uid: '',
        name: null,
        authProvider: "google",
        email: null,
        phoneNumber: null,
        photoURL: null,
        remainingPDFQuota: 0,
        lastLogin: ''
    },
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateUserData: (state, action: PayloadAction<any>) => {
            state.userData = action.payload;
        },
    },
});

export const getUserData = (state: RootState) => state.user.userData

export const { updateUserData } = userSlice.actions;

export default userSlice.reducer;
