import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import dummyImg from '../assets/benefits/career-choices.jpg'

const LazyImage = (props: any) => (
    <div>
        <LazyLoadImage
            alt={'image.alt'}
            height={50}
            src={dummyImg} // use normal <img> attributes as props
            width={50}
            {...props} />
        <span>{props?.caption}</span>
    </div>
);

export default LazyImage;