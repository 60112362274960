import React from 'react';

const HorizontalLayout = ({ children }: any) => {
    return (
        <div className="flex flex-col md:flex-row">
            <div className="md:w-1/4">
                {/* Left Section for Ads */}
                {/* Add your ad code or component here */}
            </div>

            <div className="w-full md:w-1/2">
                {children}
            </div>

            <div className="md:w-1/4">
                {/* Right Section for Ads */}
                {/* Add your ad code or component here */}
            </div>
        </div>
    );
};

export default HorizontalLayout;
