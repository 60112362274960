import React from "react";

const IntroductionSection = () => {
    return (
        <section className="bg-white py-16">
            <div className="container mx-auto px-4">
                <div className="max-w-4xl mx-auto">
                    <h1 className="text-3xl md:text-4xl font-bold text-center mb-8">
                        Introduction to Numerology
                    </h1>
                    <h2 className="text-lg text-center">
                        Numerology is the study of numbers and their influence on our lives. It is based on the belief that numbers carry energy and vibrations that can reveal valuable insights about our personality, strengths, challenges, and life path. By understanding the significance of numbers in our lives, we can gain a deeper understanding of ourselves and make informed decisions in various aspects of life, including <span className="text-blue-500">relationships</span>, <span className="text-blue-500">career</span>, and <span className="text-blue-500">personal development</span>.
                    </h2>
                </div>
            </div>
        </section>
    );
};

export default IntroductionSection;
