export const splitTextIntoChunks = (text: string, maxWords = 60) => {
    const sentences = text.split('. ');
    const chunks = [];
    let currentChunk = '';

    for (const sentence of sentences) {
        const words = sentence.split(' ');

        if (currentChunk.split(' ').length + words.length <= maxWords) {
            currentChunk = currentChunk ? `${currentChunk}. ${sentence}` : sentence;
        } else {
            chunks.push(currentChunk);
            currentChunk = sentence;
        }
    }

    if (currentChunk) {
        chunks.push(currentChunk);
    }

    return chunks;
};

export const getPdfId = ({ day, year, month }: {
    day: number, month: number, year: number
}): string => `${day}-${month}-${year}`