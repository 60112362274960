import React, { useState } from 'react';
import { AppName } from 'utils/locale'
import { processUserData } from 'utils/calculations'
import { UserDetailsQuery } from 'types';
import { updateAnalysisData } from '../store/analysisSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Track, signInWithGoogle } from '../firebase';
import { Events } from 'analyticsConstants';
import { useAuth } from 'provider/AuthContext';


function UserInput({ navigateTo }: { navigateTo?: string }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useAuth() || { user: null };
    const [name, setName] = useState('');
    const [dob, setDob] = useState('');
    const [gender, setGender] = useState<'Male' | 'Female' | ''>(''); // Added state for gender

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        console.log(name, value)
        if (name === 'name') {
            setName(value);
        } else if (name === 'dob') {
            setDob(value);
        } else if (name === 'gender') {
            setGender(value);
        }
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if (name === '' || dob === '' || gender === '') {
            alert('Please fill all details first')
            return;
        }
        if (!user?.name) {
            await signInWithGoogle();
            // dispatch(updateUserData(userData));
        }

        let userDetailsQuery: UserDetailsQuery = {
            name, dob, gender
        }

        let analysisData = processUserData(userDetailsQuery);
        dispatch(updateAnalysisData(analysisData));
        Track(Events.generateAnalysis)
        navigateTo && navigate(navigateTo);
    }

    return (
        <section className="flex flex-wrap bg-gradient-to-r from-violet-500 to-indigo-500 py-12">
            <div className="flex flex-1 justify-center items-center text-center flex-col min-w-[50%]">
                <div className="text-left min-w-fit m-auto p-8">
                    <h1 className="text-4xl font-extrabold md:text-6xl text-orange-400 mb-4">
                        {AppName}
                    </h1>
                    <h3 className="font-bold text-xl text-gray-100">
                        Unlock the Secrets of Numerology for a Life of Meaning and Fulfillment
                    </h3>
                </div>
            </div>
            <div className="flex flex-1 p-4 bg-white m-4 rounded-md shadow-lg">
                <form action={navigateTo} className="w-full max-w-md" onSubmit={handleSubmit}>
                    <div className="mb-6">
                        <label htmlFor="name" className="block mb-1 text-sm text-gray-800 font-semibold">
                            Your Name
                        </label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Full name"
                            className="input-field w-full p-2 border rounded-md focus:ring focus:ring-blue-300"
                            value={name}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="mb-6">
                        <label htmlFor="dob" className="block mb-1 text-sm text-gray-800 font-semibold">
                            Your DOB
                        </label>
                        <input
                            type="date"
                            id="date"
                            name="dob"
                            onChange={handleChange}
                            className="input-field w-full p-2 border rounded-md focus:ring focus:ring-blue-300"
                            required
                        />
                    </div>

                    <fieldset className="mb-6">
                        <legend className="sr-only">Gender</legend>
                        <div className="flex flex-col">
                            <label htmlFor="gender" className="mb-2 text-sm font-semibold text-gray-800">
                                Gender
                            </label>
                            <div className="flex space-x-4">
                                <label className="flex items-center cursor-pointer">
                                    <input
                                        id="male"
                                        type="radio"
                                        name="gender"
                                        value="Male"
                                        onChange={handleChange}
                                        className="radio-button"
                                    />
                                    <span className="ml-2 text-sm font-medium text-gray-800">Male</span>
                                </label>
                                <label className="flex items-center cursor-pointer">
                                    <input
                                        id="female"
                                        type="radio"
                                        name="gender"
                                        value="Female"
                                        onChange={handleChange}
                                        className="radio-button"
                                    />
                                    <span className="ml-2 text-sm font-medium text-gray-800">Female</span>
                                </label>
                            </div>
                        </div>
                    </fieldset>

                    <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        {!!user?.name ? 'Get your Free Report' : 'Login and see Report'}
                    </button>
                </form>
            </div>
        </section>
    );
}

export default UserInput;



