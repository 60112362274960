import React from "react";
import { Blog } from "types";
import LifePathNumber from './blogs/life-path-number-interpretation'
import LifePathNumber1 from "./blogs/life-path-number-1";
import LifePathNumber2 from "./blogs/life-path-number-2";
import LifePathNumber3 from "./blogs/life-path-number-3";
import LifePathNumber4 from "./blogs/life-path-number-4";
import LifePathNumber5 from "./blogs/life-path-number-5";
import LifePathNumber6 from "./blogs/life-path-number-6";
import LifePathNumber7 from "./blogs/life-path-number-7";
import LifePathNumber8 from "./blogs/life-path-number-8";
import LifePathNumber9 from "./blogs/life-path-number-9";

export const Blogs: Blog[] = [
    {
        path: '/blog/life-path-number-interpretation',
        title: 'Explore Your Life Path Number: What Your Birth Date Reveals',
        keywords: ['What is your Life Path number', 'destiny number', 'What does my life path number mean'],
        imageURL: require('./assets/life-path-number.webp'),
        description: `Explore the significance and calculation of Life Path Numbers. Discover what your unique number reveals about your life.`,
        component: LifePathNumber
    },
    {
        path: '/blog/life-path-number-1',
        title: `Life Path Number 1: The Leader's Guide`,
        keywords: ['numerology', 'life path number 1', 'destiny number 1'],
        imageURL: require('./assets/lifePath1.webp'),
        description: `Understand the traits and destiny of individuals with Life Path Number 1. Uncover how it influences their life journey.`,
        component: LifePathNumber1
    },
    {
        path: '/blog/life-path-number-2',
        title: `Harmony and Balance: Life Path Number 2`,
        keywords: ['numerology', 'life path number 2', 'destiny number 2'],
        imageURL: require('./assets/lifePath2.webp'),
        description: `Delve into the meaning of Life Path Number 2 and its impact on personal relationships and decision-making.`,
        component: LifePathNumber2
    },
    {
        path: '/blog/life-path-number-3',
        title: `Creativity and Expression: Life Path Number 3`,
        keywords: ['numerology', 'Life Path Number 3', 'destiny number 3'],
        imageURL: require('./assets/lifePath3.webp'),
        description: `Learn about Life Path Number 3, a symbol of creativity and self-expression. Explore how it shapes one's life experiences.`,
        component: LifePathNumber3
    },
    {
        path: '/blog/life-path-number-4',
        title: `Stability and Discipline: Life Path Number 4`,
        keywords: ['numerology', 'life path number 4', 'destiny number 4'],
        imageURL: require('./assets/lifePath4.webp'),
        description: `Discover the traits of Life Path Number 4 and how it influences one's approach to stability and achieving goals.`,
        component: LifePathNumber4
    },
    {
        path: '/blog/life-path-number-5',
        title: `Adventure and Freedom: Life Path Number 5`,
        keywords: ['numerology', 'life path number 5', 'destiny number 5'],
        imageURL: require('./assets/lifePath5.webp'),
        description: `Unravel the essence of Life Path Number 5, associated with adventure and freedom. Learn how it guides life choices.`,
        component: LifePathNumber5
    },
    {
        path: '/blog/life-path-number-6',
        title: `Love and Responsibility: Life Path Number 6`,
        keywords: ['numerology', 'life path number 6', 'destiny number 6'],
        imageURL: require('./assets/lifePath6.webp'),
        description: `Explore Life Path Number 6 and its connection to love, family, and responsibilities. Find out its significance.`,
        component: LifePathNumber6
    },
    {
        path: '/blog/life-path-number-7',
        title: `Spirituality and Insight: Life Path Number 7`,
        keywords: ['numerology', 'life path number 7', 'destiny number 7'],
        imageURL: require('./assets/lifePath7.webp'),
        description: `Dive into the spiritual aspects of Life Path Number 7 and how it influences one's pursuit of wisdom and insights.`,
        component: LifePathNumber7
    },
    {
        path: '/blog/life-path-number-8',
        title: `Abundance and Power: Life Path Number 8`,
        keywords: ['numerology', 'life path number 8', 'destiny number 8'],
        imageURL: require('./assets/lifePath8.webp'),
        description: `Understand Life Path Number 8, symbolizing abundance and power. Explore its impact on financial and career success.`,
        component: LifePathNumber8
    },
    {
        path: '/blog/life-path-number-9',
        title: `Humanitarian and Idealistic: Life Path Number 9`,
        keywords: ['numerology', 'life path number 9', 'destiny number 9'],
        imageURL: require('./assets/lifePath9.webp'),
        description: `Discover the compassionate nature of Life Path Number 9 and its role in driving humanitarian and idealistic endeavors.`,
        component: LifePathNumber9
    }
];

