import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";

export default function CheckoutForm({ data }: any) {
    const stripe = useStripe();
    const elements = useElements();
    const { amount, currency } = data;

    const [message, setMessage] = useState<string | null>(null);
    const [isProcessing, setIsProcessing] = useState(false);

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsProcessing(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: `${window.location.origin}/numerology-pdf`,
                // return_url: `${window.location.origin}/completion`,
            },
        });

        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error?.message || '');
        } else {
            setMessage("An unexpected error occured.");
        }

        setIsProcessing(false);
    };

    return (
        <form id="payment-form" onSubmit={handleSubmit} className="bg-slate-100 p-8 rounded-lg max-w-xl">
            <div className="flex items-center justify-between mb-4">
                <img
                    src={require("assets/images/pdf.webp")}
                    alt="Payment Banner"
                    className="h-12 w-12 mr-2" // Adjust margin right to provide space between the image and text
                />
                <p className="text-blue-600 font-sans text-sm">
                    Numerology Analysis Report
                </p>
            </div>

            <PaymentElement id="payment-element" />
            <button disabled={isProcessing || !stripe || !elements} id="submit" className="p-4 mt-4 bg-blue-600 rounded-lg">
                <span id="button-text" className="text-white">
                    {isProcessing ? "Processing ... " : `Pay INR ${amount / 100}/-`}
                </span>
            </button>
            {/* Show any error or success messages */}
            {message && <div id="payment-message" className="text-red-600">{message}</div>}
        </form>
    );
}