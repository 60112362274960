import { AppName } from "../utils/locale";
import { LazyImage } from '.';
import React from "react";

const HeroSection = () => {
    return (
        <section className="bg-indigo-500 text-white py-16 font-inter">
            <div className="container mx-auto px-4">
                <div className="flex flex-col md:flex-row items-center">
                    <div className="md:w-1/2">
                        <h1 className="text-4xl md:text-6xl font-bold mb-6">
                            {`Welcome to`}
                        </h1>
                        <h1 className="text-4xl md:text-6xl font-bold mb-6">
                            {AppName}
                        </h1>
                        <p className="text-lg md:text-xl mb-8">
                            Get insights into your life, relationships, and career through the power of numerology.
                        </p>
                        <a href='/numerology-calculator' className="bg-white text-blue-500 font-bold py-3 px-6 rounded-full shadow-lg uppercase tracking-wide hover:bg-gray-100">
                            Generate Analysis
                        </a>
                    </div>
                    <div className="md:w-1/2 mt-8 md:mt-0">
                        <LazyImage
                            src={require('assets/images/hero.webp')}
                            alt="Numerology"
                            className="w-full h-auto rounded-lg"
                            width={1200}
                            height={800}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HeroSection;
