import { UserDetailsQuery, LoshuGrid, Date, AnalysisParams } from '../types'
import { CHALDEAN_NAME_NUMBER } from './locale'

export const processUserData = (data: UserDetailsQuery): AnalysisParams => {
    const { name, dob, gender } = data;
    let dobArray = dob.split('-');
    const year = parseInt(dobArray[0]);
    const month = parseInt(dobArray[1]);
    const day = parseInt(dobArray[2]);

    const psychicNumber: number = sumRecursively(day);
    const lifePathNumber: number = sumRecursively(day + month + year);
    const kuaNumber: number = getKuaNumber({ year, gender })
    const nameNumber: number = getNameSum(name);
    const loshuGrid: LoshuGrid = getLoshuGrid({ day, month, year, psychicNumber, lifePathNumber, kuaNumber });
    const personalYear: number = getPersonalYear({ day, month });

    return { day, year, month, psychicNumber, lifePathNumber, kuaNumber, nameNumber, personalYear, loshuGrid, name };
}

export const getPersonalYear = ({ day, month }: {
    day: number,
    month: number
}) => {
    let currentYear = new window.Date().getFullYear();
    let sum = sumRecursively(day) + sumRecursively(month) + sumRecursively(currentYear);
    return sumRecursively(sum);
}

export const getLoshuGrid = ({ day, month, year, psychicNumber, lifePathNumber, kuaNumber }: Date & {
    psychicNumber: number;
    lifePathNumber: number;
    kuaNumber: number;
}): LoshuGrid => {
    let loshuGrid: LoshuGrid = {
        '1': 0,
        '2': 0,
        '3': 0,
        '4': 0,
        '5': 0,
        '6': 0,
        '7': 0,
        '8': 0,
        '9': 0,
    };
    let acc: string;
    acc = Object.values([day, month, year]).reduce((ac, i) => ac + i, '');
    acc = day > 9 ? acc + psychicNumber : acc;
    acc += lifePathNumber;
    acc += kuaNumber;

    Object.values(acc).forEach((element: string): void => {
        loshuGrid[element] += 1;
    });

    return loshuGrid;
}

const getKuaNumber = ({ year, gender }: {
    year: number,
    gender: 'Male' | 'Female'
}) => {
    let kuaNumber = gender === 'Male'
        ? sumRecursively(11 - sumRecursively(year))
        : sumRecursively(year + 4);
    return kuaNumber;
}

export const sumRecursively = (acc: number): number => {
    let n: number = Math.abs(acc);
    let sumOfDigits: number = 0;
    while (n) {
        let remainder = n % 10;
        sumOfDigits = sumOfDigits + remainder;
        n = Math.floor(n / 10);
    }
    return sumOfDigits > 9 ? sumRecursively(sumOfDigits) : sumOfDigits;
};

export const getNameSum = (name: string): number => {
    const sum: number = Object.values(name).reduce(
        (ac, i) => {
            const item: string = i.toUpperCase();
            return ac + (CHALDEAN_NAME_NUMBER[item] || 0)
        },
        0,
    );
    return !isNaN(sum) ? sumRecursively(sum) : 0;
};