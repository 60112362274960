import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Use local storage as the storage mechanism
import userReducer from './userSlice';
import analysisReducer from './analysisSlice';
import paymentReducer from './paymentSlice';


const persistConfig = {
    key: 'root', // Key for storing the data in local storage
    storage, // Use local storage
};

const store = configureStore({
    reducer: {
        user: persistReducer(persistConfig, userReducer),
        analysis: persistReducer(persistConfig, analysisReducer),
        payment: persistReducer(persistConfig, paymentReducer)
    },
});

export const persistor = persistStore(store); // Persist the store

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
