import React from 'react'
import { ToolHighlights } from '../utils/locale';

export default function ToolOffers() {
    const row = (item: {
        title: string;
        description: string
    }) => (
        <div className="p-4 bg-white rounded-md shadow-md" key={item.title}>
            <h3 className="text-xl font-bold mb-2 text-center text-indigo-600">{item.title}</h3>
            <p className="text-gray-800">{item.description}</p>
        </div>
    )
    return (
        <section className="p-8 bg-gray-100">
            <h2 className="text-3xl font-bold mb-6 text-center">Discover What Our Numerology Tool Offers</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {
                    ToolHighlights.map(row)
                }
            </div>
        </section>
    )
}
