import NumberImpact from '../utils/numerologyData/numberImpact';
import { LoshuGrid } from '../types'
import React from 'react'
import ReadableContent from './ReadableContent';


const GridDetails = ({ data }: { data: LoshuGrid }) => {
    const gridValues = [4, 9, 2, 3, 5, 7, 8, 1, 6];

    return (
        <div>
            {
                gridValues.map((value, index) => {
                    const digits = data[value] ? String(value).repeat(data[value]) : '';
                    const hasDigits = digits !== '';
                    const isMissing = !data[value] || data[value] === 0;

                    if (hasDigits) {
                        return (
                            <ReadableContent
                                key={value}
                                header={`Impact of ${digits}`}
                                content={NumberImpact[value][data[value] - 1]}
                            />
                        )
                    }


                }
                )}
        </div>
    );
};

export default GridDetails