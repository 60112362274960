import firebase from 'firebase/compat/app';
import { getAnalytics, logEvent } from "firebase/analytics";
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
} from "firebase/auth";
import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    doc,
    addDoc,
    updateDoc,
} from "firebase/firestore";
import { USER } from "types";

const firebaseConfig = {
    apiKey: "AIzaSyDE-cdwyLQLr3bya7bkOJLORLf3OMD5u3M",
    authDomain: "numero-gyan.firebaseapp.com",
    projectId: "numero-gyan",
    storageBucket: "numero-gyan.appspot.com",
    messagingSenderId: "169577469550",
    appId: "1:169577469550:web:6156cb8dee7c8cbae1b87f",
    measurementId: "G-FQX8NLB1ZC"
};

const app = firebase.initializeApp(firebaseConfig);
export const auth = getAuth(app);
const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();

export const signInWithGoogle = async () => {
    try {
        const res = await signInWithPopup(auth, googleProvider);
        const user = res.user;

        const userRef = collection(db, 'users');
        const q = query(collection(db, "users"), where("email", "==", user.email));

        let userData: USER = {
            uid: user.uid,
            name: user.displayName,
            authProvider: "google",
            email: user.email,
            phoneNumber: user.phoneNumber,
            photoURL: user.photoURL,
            remainingPDFQuota: 1,
            lastLogin: user?.metadata?.lastSignInTime || ''
        }

        getDocs(q)
            .then((querySnapshot) => {
                if (querySnapshot.size === 0) {
                    // Step 3: No document found, create a new one
                    addDoc(userRef, userData)
                        .then(() => {
                            console.log('### New user added to Firestore',);
                        })
                        .catch((error) => {
                            console.error('### Error adding new user: ', error);
                        });
                } else {
                    // Step 2: Document found, update it
                    const userDoc = querySnapshot.docs[0]; // Assuming there's only one document with the same email
                    const userDocRef = doc(db, 'users', userDoc.id);
                    console.log('### userDoc', userDoc.data())
                    updateDoc(userDocRef, {
                        lastLogin: user?.metadata?.lastSignInTime || ''
                    })
                        .then(() => {
                            userData.lastLogin = user?.metadata?.lastSignInTime || '';
                        })
                        .catch((error) => {
                            console.error('### Error updating user document: ', error);
                        });
                }

            })
            .catch((error) => {
                console.error('### Error checking for existing user: ', error);
            });
        return userData;
    } catch (err: any) {
        console.error(err);
        alert(err.message);
        return {};
    }
}


export const signOutGoogle = async (user: any = auth) => await signOut(user);

const analytics = getAnalytics(app);

const isProd = process.env.NODE_ENV !== 'development'

export const Track = (event: string) => isProd && logEvent(analytics, event);