import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { createLazyRoute } from 'utils/routes'; // Import the utility function
import store, { persistor } from './store';
import { AuthContextProvider } from "./provider/AuthContext";
import { Blogs } from 'containers/Blog/meta';
// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';


const Home = React.lazy(() => import('containers/Home'));
const About = React.lazy(() => import('containers/About'));
const Contact = React.lazy(() => import('containers/Contact'));
const NumerologyAnalysis = React.lazy(() => import('containers/NumerologyAnalysis'));
const NumerologyCalculator = React.lazy(() => import('containers/NumerologyCalculator'));
const PDFDownload = React.lazy(() => import('containers/PDFDownload'));
const NumerologyPDF = React.lazy(() => import('containers/NumerologyPDF'));
const BlogPage = React.lazy(() => import('containers/Blog'))


// Define lazy-loaded routes using the utility function
const routes = [
  createLazyRoute('/', () => <Home />),
  createLazyRoute('/about', () => <About />),
  createLazyRoute('/contact', () => <Contact />),
  createLazyRoute('/numerology-analysis', () => <NumerologyAnalysis />),
  createLazyRoute('/pdf-download-page', () => <PDFDownload />),
  createLazyRoute('/numerology-calculator', () => <NumerologyCalculator />),
  createLazyRoute('/numerology-pdf', () => <NumerologyPDF />),
  createLazyRoute('/blog', () => <BlogPage />),
];


function App() {
  return (
    <Provider store={store}>
      <AuthContextProvider>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <Routes>
              {routes.map((route, index) => (
                <Route key={index} {...route} />
              ))}
              {
                Blogs.map(item => <Route key={item.path} path={item.path} Component={() => item.component(item)} />)
              }
            </Routes>

          </Router>
        </PersistGate>
      </AuthContextProvider>
    </Provider>
  );
}

export default App;
