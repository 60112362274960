import React from 'react';

interface BlogQuoteProps {
    text?: string;
    author?: string; // Author is optional,
    children?: string
}

const BlogQuote: React.FC<BlogQuoteProps> = ({ text, author, children }) => {
    return (
        <blockquote className="text-xl italic border-l-4 border-indigo-500 pl-4 my-8">
            {text || children}
            {author && <p className="text-right">— {author}</p>}
        </blockquote>
    );
};

export default BlogQuote;
