import React from 'react';

const CTASection = () => {
    return (
        <section className="bg-gray-900 py-8">
            <div className="container mx-auto px-4">
                <h3 className="text-center text-3xl text-white font-bold mb-4">
                    Ready to Unlock the Power of Numerology?
                </h3>
                {/* <p className="text-center text-gray-300 mb-8">
                    Sign up today and get instant access to your personalized numerology analysis.
                </p> */}
                {/* <div className="flex justify-center">
                    <a
                        href="/signup"
                        className="inline-block bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-full transition duration-300"
                    >
                        Sign Up Now
                    </a>
                </div> */}
            </div>
        </section>
    );
};

export default CTASection;
