import React from 'react';

interface BlogImageProps {
    src: string;
    alt: string;
    tags: string[];
}

const BlogImage: React.FC<BlogImageProps> = ({ src, alt, tags }) => {
    return (
        <div className="flex flex-col items-center"> {/* Use Flexbox to center content */}
            <img src={src} alt={alt} className="mb-3" />
            <div className="flex flex-wrap">
                {tags.map((tag, index) => (
                    <span key={index} className="text-indigo-600 bg-indigo-200 px-2 py-1 rounded-full m-1">
                        #{tag}
                    </span>
                ))}
            </div>
        </div>
    );
};

export default BlogImage;
