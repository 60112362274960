import React, { ReactNode } from 'react';

interface BlogSubtitleProps {
    subtitle?: string;
    children?: ReactNode;
}

const BlogSubtitle: React.FC<BlogSubtitleProps> = ({ subtitle, children }) => {
    const subtitleContent = subtitle || (typeof children === 'string' ? children : '');

    return (
        <h2
            className="text-2xl font-semibold my-8"
            dangerouslySetInnerHTML={{ __html: subtitleContent }}
        />
    );
};

export default BlogSubtitle;
