import React from 'react';

interface LinkProps {
    to: string;
    children: React.ReactNode;
    target?: string;
    rel?: string;
}

const Link: React.FC<LinkProps> = ({ to, children, target, rel }) => {
    return (
        <a
            href={to}
            target={target}
            rel={rel}
            className="font-bold text-blue-500"
        >
            {children}
        </a>
    );
};

export default Link;
